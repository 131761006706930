.mall-cart-summary {
    &__info {
        @apply .flex .justify-between .text-xs .leading-normal .mb-8;
        > * {
            @apply .w-1/4;
            &:not(:last-child) {
                @apply .pr-8;
            }
        }
    }
    &__heading {
        @apply .border-b .border-smoke .pb-1 .mb-2;
    }
    &__method-logo {
        @apply .max-w-full .pt-4;
        min-height: 140px;
    }
    a {
        @apply .text-black;
    }
    .mall-address {
        @apply .flex .justify-between .flex-col;
        min-height: 160px;
        &__actions {
            @apply .mt-8;
        }
    }
}