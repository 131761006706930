.mall-payment-method {
    @apply .mt-4;
    &__entry {
        @apply .flex .py-8 .relative .items-center .z-0 .border .border-smoke;
        &--active {
            @apply .bg-white .items-start .shadow-md .opacity-100 .z-10 .border .border-grey;
            .mall-payment-method__text {
                @apply .opacity-100;
            }
        }
    }
    &__logo {
        width: 180px;
        @apply .flex .items-start .justify-center;
        img {
            @apply .w-auto .max-w-full .block;
        }
    }
    &__text {
        @apply .flex-1 .flex .items-center .opacity-50;
        div {
            @apply .w-full;
        }
    }
    &__name {
        @apply .font-bold;
    }
    &__description,
    &__instructions {
        @apply .text-xs .leading-tight .mt-4;
    }
    &__meta {
        @apply .text-sm .leading-normal .text-grey .mt-2;
    }
    &__data {
        @apply .mt-8 .w-full;
    }
    &__selector {
        width: 180px;
        @apply .relative .z-20;
    }
    &__select-button {
        @apply .float-right .mall-btn .mr-4 .whitespace-no-wrap;
    }
    &__submit {
        @apply .mt-4;
    }
    .mall-form-control {
        @apply .mb-2;
    }
    [data-validate-for] {
        @apply .mb-4 .mt-0;
    }
}