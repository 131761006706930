.mall-checkout-result {
    @apply .w-1/2 .m-auto .p-8 .rounded .shadow-md .text-center;
    &--failed {
        @apply .bg-red-lightest .border .border-red-light .text-red-darkest;
    }
    &--cancelled {
        @apply .bg-orange-lightest .border .border-orange-light .text-orange-darkest;
    }
    &__heading {
        @apply .mb-4;
    }
    &__actions {
        @apply .mt-8;
    }
    p {
        @apply .mb-4;
    }
}

.mall-overlay {
    background: rgba(255, 255, 255, .8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}