.mall-table {
    @apply .w-full .text-left;
    border-collapse: collapse;

    th {
        @apply .text-sm .font-semibold .text-grey-darker .p-2 .bg-grey-lightest;
    }

    td {
        @apply .p-2 .border-t .text-xs .whitespace-no-wrap .leading-normal;
    }
}