h1 {
    @apply .mb-6;
}
h2 {
    @apply .mb-4;
}
h3 {
    @apply .mb-2;
}
p {
    @apply .leading-normal;
}
a {
    @apply .text-primary;
}
.text-muted {
    @apply .text-grey-dark;
}